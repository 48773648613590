import { useTranslation } from 'react-i18next'
import { FilterChipItem } from './FilterChipItem'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigationEnum } from '../../../utils/constants'

interface IProps {
  titles: string[] | []
  icon?: 'CHECK' | 'SEARCH' | 'CLEAR'
  handleSearch?: (key?: string) => void
  handleDelete?: (key?: string) => void
  isVisibleChips?: boolean
  isNoSearch?: boolean
}

export const FilterChipsList = (props: IProps) => {
  const {isVisibleChips, isNoSearch} = props;
  const [visibleChips, setVisibleChips] = useState(0);
  const containerRef = useRef<any>(null);
  const { t } = useTranslation()
  const location = useLocation();
  useEffect(() => {
    if(!isVisibleChips) {
      const containerWidth = containerRef?.current?.offsetWidth;
      const chipWidth = 90; // Replace this with the actual width of your chip
      const numberOfChips = Math.floor(containerWidth / chipWidth);
      setVisibleChips(numberOfChips);
    }

  }, [isVisibleChips]);
  return (
    <>
      {isVisibleChips && props.titles.length > 0 && location.pathname === NavigationEnum.ASSETS && <span style={{ color: '#000', fontWeight: 600, fontSize: 14 }}>{t('tags')} </span>}
      <div ref={containerRef} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {(isVisibleChips ? props.titles : props.titles.slice(0, visibleChips)).map((title, index) => (
          <FilterChipItem
            key={`${title}-${index}`}
            title={title}
            icon={props.icon}
            isNoSearch={isNoSearch}
            handleSearch={() => {
              if (props?.handleSearch) {
                props.handleSearch(title)
              }
            }}
            handleDelete={() => {
              if (props.handleDelete) {
                props.handleDelete(title)
              }
            }}
          />
        ))}
      </div>
    </>
  )
}
