import {
  Box,
  Button,
  Grid,
  ImageList,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Notiflix from 'notiflix'
import { useDispatch, useSelector } from 'react-redux'

// Hooks
import useViewportDimensions from '../../../../hooks/useViewportDimensions'

// Providers
import instance from '../../../../providers/axios.config'

// Utils
import { IFilters, initialFiltersConst } from '../../../../utils/constants'

// Shared components
import { MainAssetsCard } from '../../../shared-components/card/MainAssetsCard'
import { CustomPagination } from '../../../shared-components/pagination/CustomPagination'
import SkeletonAssetsImages from '../../../shared-components/skeleton/assetsImages'
import NoImagesFound from '../../../shared-components/noImagesFound'

// SCSS
import styles from './Assets.module.scss'

// Child
import { HeaderSearchForm } from './HeaderSearchForm'

// Redux
import {
  setAdvancedSearch, setAiNetwork,
  setCategories, setModel,
  setNumberOfItems,
  setOrientation,
  setSearch, setSortBy,
  setTypes
} from '../../../../redux/reducers/searchReducer'

const colsScreen = (screen: number) => {
  console.log('screen', screen);
  if (screen < 600) {
    return 4
  } else if (screen > 600 && screen < 900) {
    return 6
  } else if (screen > 900 && screen < 1200) {
    return 8
  } else if (screen > 1200 && screen < 1560) {
    return 10
  } else if (screen > 1560 && screen < 2200) {
    return 12
  } else if (screen > 2200 && screen < 3000) {
    return 12
  } else if (screen > 3000 && screen < 4000) {
    return 12
  } else if (screen > 4000 && screen < 5000) {
    return 12
  } else if (screen > 5000 && screen < 6000) {
    return 12
  }
  return 10
}
const constructUrlSearch = (filters: any) => {
  const orientationFilter = filters?.orientation && filters?.orientation !== 'all' ? `&filters[orientation]=${filters?.orientation}` : ''
  const typeFilter = filters?.typeFilter?.length ? filters?.typeFilter.map((type: string) => {
    if (type === 'editorial') {
      return `&filters[is_editorial]=1`
    }
    if (type === 'adult_content') {
      return `&filters[is_adult]=1`
    }
    if (type === 'generated_by_ai') {
      return `&filters[is_ai]=1`
    }
  }) : ''
  const typeFilterReplace = typeFilter?.toString() ? typeFilter?.toString()?.replaceAll(',', '') : ''
  const categoriesFilter = filters?.category ? `&filters[categories]=${filters?.category?.toLowerCase()}` : ''
  const advancedSearchId = filters?.advancedSearch?.itemId ? `&filters[id]=${filters?.advancedSearch?.itemId}` : ''
  const advancedModelSearch = filters?.model ? `&filters[model]=${filters?.model}` : ''
  const advancedMinorSearch = filters?.minor ? `&filters[minor]=${filters?.minor}` : ''
  const advancedSearchAuthor = filters?.advancedSearch?.author ? `&filters[author]=${filters?.advancedSearch?.author}` : ''
  const advancedSearchDate = filters?.advancedSearch?.date ? `&filters[date]=${filters?.advancedSearch?.date}` : ''
  const search = filters?.searchFilter ? `&q=${filters?.searchFilter}` : ''
  const numberItems = filters?.items ? `&limit=${filters?.items}` : `&limit=100`
  const aiNetwork = filters?.ai_network ? `&filters[network_ai]=${filters?.ai_network}` : ''
  const sortByFilter = filters?.sortByFilter ? `&filters[sortBy]=${filters?.sortByFilter}` : ''
  return `${orientationFilter}${typeFilterReplace}${categoriesFilter}${advancedSearchId}${search}${numberItems}${advancedSearchAuthor}${aiNetwork}${advancedSearchDate}${advancedModelSearch}${advancedMinorSearch}${sortByFilter}`
}
export const Assets = () => {
  const { t } = useTranslation()

  const screenSize = useViewportDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const [allImagesAssets, setAllImagesAssets] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 100,
    totalPages: 1
  })
  const handleChange = (_: React.ChangeEvent, value: number) => {
    setPagination({...pagination, currentPage: value})
  }
  const handleNextPageChange = () => {
    setPagination({...pagination, currentPage: pagination.currentPage + 1})
  }
  const dispatch = useDispatch()
  const location = useLocation();
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const sm = useMediaQuery(theme.breakpoints.down('md'))
  const md = useMediaQuery(theme.breakpoints.down('lg'))
  const lg = useMediaQuery(theme.breakpoints.down('xl'))

  const getMasonryColumns = () => {
    if (xs) return { col: 1, gap: 0 }
    if (sm) return { col: 2, gap: 0 }
    if (md) return { col: 3, gap: 1 }
    if (lg) return { col: 5, gap: 1 }
    return { col: 5, gap: 1 }
  }
  const masonryOptions = getMasonryColumns()
  useEffect(() => {
    if (filters) {
      const orientationFilter = filters?.orientation && filters?.orientation !== 'all' ? filters?.orientation : ''
      const searchFilter = filters?.searchFilter ? filters?.searchFilter : ''
      const sortByFilter = filters?.sortByFilter ? filters?.sortByFilter : ''
      const itemsFilter = filters?.items ? filters?.items : 0
      const typeFilter = filters?.typeFilter?.length ? filters?.typeFilter : []
      const typeCategory = filters?.category ? filters?.category : ''
      const advancedSearch = filters?.advancedSearch ? filters?.advancedSearch : {}
      const aiNetwork = filters?.ai_network ? filters?.ai_network : ''
      const model = filters?.model ? filters?.model : ''
      dispatch(setCategories(typeCategory))
      dispatch(setTypes([...typeFilter]))
      dispatch(setOrientation(orientationFilter))
      dispatch(setSearch(searchFilter))
      dispatch(setNumberOfItems(itemsFilter?.toString()))
      dispatch(setAdvancedSearch(advancedSearch))
      dispatch(setAiNetwork(aiNetwork))
      dispatch(setModel(model))
      dispatch(setSortBy(sortByFilter))
    }
  }, [])
  const getAssets = async () => {
    setIsLoading(true)
    try {
      const urlData = constructUrlSearch(filters)
      const res = await instance.get(`search?page=${pagination.currentPage}${urlData}`)
      setAllImagesAssets(res?.data?.list)
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getAssets()
  }, [location.search, pagination?.currentPage])

  const calcFlexBasis = (width: number, image: any) => {
    return `calc((${image.medium_width || 200} / 260) * ${width}px)`;
  };

  const calcFlexGrow = (image: any) => {
    return `calc((${image.medium_width || 200}) * 100)`;
  };
  return (
    <>
      <Box className={isLoading ? '' : styles.container} sx={{ width: '100%' }}>
        <HeaderSearchForm />

        {isLoading ? <SkeletonAssetsImages /> : <Grid
          sx={{ width: '95%', backgroundColor: '#fff' }}
          container
          justifyContent={'center'}
          alignItems='center'
        >
          {!allImagesAssets?.length 
            ? <NoImagesFound /> 
            : (
              // <ImageList
              //   sx={{ overflow: 'hidden' }}
              //   variant='standard'
              //   cols={colsScreen(screenSize.width)}
              //   gap={masonryOptions.gap}
              // >
              //   {allImagesAssets.map((image: any) => (
              //     <div style={{ width: 'auto', height: 'auto' }} key={`assets-images-${image.id}`}>
              //       <MainAssetsCard
              //         isFavorite={false}
              //         description={image?.title}
              //         image={image?.mediumURL}
              //         imageId={image?.id}
              //       />
              //     </div>
              //   ))}
              // </ImageList>
              <Box sx={{ width: '100%' }} position={'relative'}>
                <Grid container spacing={2} gap={2}>
                  {allImagesAssets.map((image: any) => (
                    <Grid
                      margin={0}
                      padding={0}
                      sx={{ 
                        // backgroundColor: 'red',
                        height: 300,
                        overflow: 'hidden',
                        flexBasis: {
                          sm: calcFlexBasis(110, image),
                          md: calcFlexBasis(170, image),
                          lg: calcFlexBasis(200, image),
                          // all: calcFlexBasis(200, image)
                        },
                        flexGrow: calcFlexGrow(image)
                      }}
                      key={`assets-images-${image.id}`}
                    >
                      <MainAssetsCard
                        isFavorite={false}
                        description={image?.title}
                        image={image?.mediumURL}
                        imageId={image?.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
        </Grid>}
        {pagination?.totalPages > 1 && <div className={styles.paginationContainer}>
          <Button variant='contained' className={styles.nextButton} onClick={handleNextPageChange}>
            {t('Next')}
          </Button>

          <CustomPagination
            shape='rounded'
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
            size='large'
            className={styles.pagination}
          />
        </div>}
      </Box>
    </>
  )
}
