import {
  Avatar, Box, Button, Link

} from '@mui/material'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

export const CustomCarousel = (props: any) => {

  const {images, imageRedirect} = props;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };
  return (
    <Carousel partialVisible={true} responsive={responsive}>
      {images.map((image: any) => (
        <img key={image.id} src={image.thumbURL} alt={image} style={{cursor: 'pointer'}} onClick={() => imageRedirect(`${image?.title?.replaceAll(' ', '-')}-${image.id}`, image.id)}/>
      ))}
    </Carousel>

  )
}