import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, FormControlLabel, Button } from '@mui/material'
import {
  setImageProperty,
  setUploadSession,
} from '../../../../../../../redux/reducers/uploadSessionReducer'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import styles from './Step2.module.scss'
import { VALID } from '../../../../../../../utils/constants'

export const Step2: React.FC<{ saveStep: () => void, onDeleteAllImages?: () => void; handleDeleteImage?: (key?: string| number) =>void; }> = ({ saveStep, onDeleteAllImages, handleDeleteImage }) => {
  const { t } = useTranslation()

  const [selectedElements, setSelectedElements] = useState<number[]>([])
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false)
  const [editorial, setEditorial] = useState(false)
  const [adultContent, setAdultContent] = useState(false)
  const [generatedByAI, setGeneratedByAi] = useState(false)
  const [aiNetworkUsed, setAiNetworkUsed] = useState('')
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const profile = useSelector(
    (state: RootState) => state.user.usersData
  )
  const dispatch = useDispatch()

  const handleDeleteAll = () => {
    if(onDeleteAllImages) {
      onDeleteAllImages()
    }
    handleOpen()
  }

  const handleOpen = () => {
    setOpenDeleteAllDialog(!openDeleteAllDialog)
  }

  const handleSelectAll = (checked: boolean) => {
    const selectedElementsCopy = checked
      ? uploadSession?.images?.map((el) => el.imageId) || []
      : []
    setSelectedElements(selectedElementsCopy)
  }

  const handleSelectAllCheckboxes = (type: string, checked: boolean) => {
    if(type === 'isAdult') {
      setAdultContent(checked)
    }
    if(type === 'isEditorial') {
      setEditorial(checked)
    }
    if(type === 'isAi') {
      setGeneratedByAi(checked)
    }
    if (uploadSession?.images) {
      const updatedImages = uploadSession.images.map((image) => {
        return {
          ...image,
          [type]: selectedElements.includes(image.imageId)
            ? checked
              ? 1
              : 0
            : (image as any)[type as any],
        }
      })
      dispatch(setUploadSession({ ...uploadSession, images: updatedImages }))
    }
  }
  const handleChangeAiNetwork = (value: string) => {
    if (uploadSession?.images) {
      setAiNetworkUsed(value)
      const updatedImages = uploadSession.images.map((image) => {
        return selectedElements.includes(image.imageId)
          ? {
              ...image,
              networkAi: value,
            }
          : image
      })
      dispatch(setUploadSession({ ...uploadSession, images: updatedImages }))
    }
  }

  return (
    <>
      <ConfirmDialog
        open={openDeleteAllDialog}
        handleOpen={handleOpen}
        handleConfirm={handleDeleteAll}
        dialogMessage={t('Are you sure to delete all of the images')}
        dialogTitle={t('Delete All Images')}
      />

      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <span className={styles.step}>{t('Step', { step: 2 })}</span>
          <span className={styles.title}>{t('Options')}</span>
        </div>
        <div className={styles.optionsContainer}>
          <FormControl
            component='fieldset'
            className={styles.selectAllCheckbox}
          >
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={
                    selectedElements.length === uploadSession?.images?.length
                  }
                  onChange={(e: any) => handleSelectAll(e.target.checked)}
                />
              }
              label={t('Select All')}
            />
          </FormControl>
          <FormControl component='fieldset' className={styles.options}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={adultContent}
                  onChange={(e: any) => {
                    handleSelectAllCheckboxes('isAdult', e.target.checked)
                  }}
                />
              }
              label={t('Adult content')}
              className={styles.checkbox}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={editorial}
                  onChange={(e: any) => {
                    handleSelectAllCheckboxes('isEditorial', e.target.checked)
                  }}
                />
              }
              label={t('Editorial')}
              className={styles.checkbox}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={generatedByAI}
                  onChange={(e: any) => {
                    handleSelectAllCheckboxes('isAi', e.target.checked)
                  }}
                />
              }
              label={t('Generated by AI')}
              className={styles.checkbox}
            />
            {generatedByAI && (
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='ai_network'
                variant='outlined'
                value={aiNetworkUsed}
                onChange={(e: any) => handleChangeAiNetwork(e.target.value)}
                label={t('AI Network used')}
              />
            )}
          </FormControl>
        </div>

        <div className={styles.elements}>
          {uploadSession?.images?.map((el, index) => (
            <div key={index} className={styles.elementContainer}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={selectedElements.includes(el.imageId)}
                    onChange={(e: any) => {
                      const imageId = el.imageId
                      setSelectedElements((prevSelected) =>
                        e.target.checked
                          ? [...prevSelected, imageId]
                          : prevSelected.filter((id) => id !== imageId)
                      )
                    }}
                  />
                }
                label={''}
                className={styles.checkbox}
              />

              <PublicationImageCard
                image={el.thumbnail}
                imageId={el.imageId}
                name={el?.originalFilename}
                canBeDeleted={profile?.displayStatus?.toLowerCase() === VALID}
                onDeleteImage={profile?.displayStatus?.toLowerCase() === VALID ? handleDeleteImage : {}}
              />

              <div className={styles.elementButtonsContainer}>
                <div className={styles.elementOptionsContainer}>
                  <FormControl component='fieldset'>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          onChange={(e: any) => {
                            dispatch(
                              setImageProperty({
                                imageId: el.imageId,
                                propertyName: 'isAdult',
                                value: e.target.checked,
                              })
                            )
                          }}
                          checked={!!el.isAdult}
                        />
                      }
                      label={t('Adult content')}
                      className={styles.checkbox}
                    />
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          onChange={(e: any) => {
                            dispatch(
                              setImageProperty({
                                imageId: el.imageId,
                                propertyName: 'isEditorial',
                                value: e.target.checked,
                              })
                            )
                          }}
                          checked={!!el.isEditorial}
                        />
                      }
                      label={t('Editorial')}
                      className={styles.checkbox}
                    />
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          onChange={(e: any) => {
                            dispatch(
                              setImageProperty({
                                imageId: el.imageId,
                                propertyName: 'networkAi',
                                value: '',
                              })
                            )
                            dispatch(
                              setImageProperty({
                                imageId: el.imageId,
                                propertyName: 'isAi',
                                value: e.target.checked,
                              })
                            )
                          }}
                          checked={!!el.isAi}
                        />
                      }
                      label={t('Generated by AI')}
                      className={styles.checkbox}
                    />
                  </FormControl>
                  {!!el.isAi && (
                    <CustomTextfield
                      className={styles.elementTextfield}
                      size='small'
                      name='ai_network'
                      variant='outlined'
                      value={el?.networkAi || ''}
                      onChange={(e: any) => {
                        dispatch(
                          setImageProperty({
                            imageId: el.imageId,
                            propertyName: 'networkAi',
                            value: e.target.value,
                          })
                        )
                      }}
                      label={t('AI Network used')}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.bottomButtons}>
          <Button onClick={saveStep} className={styles.outsideButton}>
            {t('Save All')}
          </Button>
          <Button className={styles.outsideButton} onClick={handleOpen}>
            {t('Delete All')}
          </Button>
        </div>
      </div>
    </>
  )
}
